<template>
  <div class="main">
        <div class="title">
      <div class="titleBox" @click="GoLunBoUrl(smallLB[0].Description)">
        <img src="../../assets/img/zmb_location.png" alt="" />
        <span>区域地理位置</span>
      </div>
      <div class="titleBox" @click="goZMlist('/ZMBorientation')">
        <img src="../../assets/img/zmb_orientation.png" alt="" />
        <span>功能定位</span>
      </div>
      <div class="titleBox">
        <img src="../../assets/img/zmb_innovate.png" alt="" />
        <span>创新案例名录</span>
      </div>
      <div class="titleBox" @click="goZMlist('/ZMBpolicy')">
        <img src="../../assets/img/zmb_policy.png" alt="" />
        <span>相关政策</span>
      </div>
      <!-- <div class="titleBox" @click="goZMlist('/ZMBservice')">
        <img src="../../assets/img/zmb_services.png" alt="" />
        <span>企业服务</span>
      </div> -->
      <div class="titleBox" @click="goZMlist('/ZMBPolicyInformation')">
        <img src="../../assets/img/zmb_news.png" alt="" />
        <span>政策资讯</span>
      </div>
    </div>
    <!-- header end-->
         <div class="textbox" style="padding: 30px 0 0 0">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item :to="{path:'/freeTrade'}">自贸专栏</el-breadcrumb-item>
        <el-breadcrumb-item>案例名录</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <!-- body start -->
    <div class="bodybox">
      <div class="textbox">


        <div class="listbox" v-for="(item, i) in NewsList" :key="i" @click="InformationDetails(item.SysID)">
          <div class="imgbox">
            <el-image
              style="width: 100%; height: 100%"
              :src="item.FirstImg"
              fit="cover"
            ></el-image>
          </div>
          <div class="midbox">
            <div class="title">{{ item.Title }}</div>
            <div class="Intro">{{ item.Intro }}</div>
          </div>

          <div class="bottombox">
            <span class="adress">{{ item.CompanyName }}</span>
            <span class="time">{{ item.CreateOn }}</span>
          </div>
        </div> 
      </div>

                <div class="more" v-if="IsShowMore == 1">
            <span @click="GetPageMore()"
              >加载更多 <i class="el-icon-d-arrow-right"></i
            ></span>
          </div>
          <div class="more" v-if="IsShowMore != 1 && NewsList.length">
            <em>已加载全部 </em>
          </div>
          <div class="more" v-if="IsShowMore != 1 && !NewsList.length">
            <em>暂无数据 </em>
          </div>
    </div>
    <!-- body end -->
  </div>
</template>

<script>
import timeFormat from "../../util/time.js";
// 公共样式
import "../../assets/css/common.css";
export default {
  data() {
    return {
      activeIndex: "5",
      drawer: false,
      windowWidth: document.documentElement.clientWidth, //实时屏幕宽度
      NewsList: [],
      NewCompanyList: [],
      NewNewsList: [],
      CateSysID: "",
      CurCateName: "",
      CompanyTypeSysID: "",
      SaleTypeSysID: "",
      CateList: [],
      indexaa: -1,
      CompanyTypeList: [],
      indexbb: -1,
      SaleTypeList: [],
      indexcc: -1,
      PageIndex: 1,
      PageSize: 6,
      IsShowMore: 1,
      smallLB: [],
    };
  },

  watch: {
    windowWidth(val) {
      let that = this;
      console.log("实时屏幕宽度：", val);
      if (val > 990) {
        that.drawer = false;
      }
    },
  },
  methods: {
       //轮播跳转
    GoLunBoUrl(url) {
      if (url != null && url != "" ) {
        window.location.href=url;
      }
    },
    getLBsmail() {
      let that = this;
      that
        .$post("api/SysNews/List", {
          State: true,
          Type: "自贸区地理位置",
          IsHome: true,
          Sort:'Sort ASC'
        })
        .then(function (res) {
          console.log(res);
          let data = res.Obj;
          data.forEach((v) => {
            if (v.Img) {
              v.Img = that.urlOA + v.Img.substr(1);
            }
          });
          that.smallLB = data;
        });
    },
      goZMlist(url) {
      this.$router.push(url);
    },
    //   企业详情
    getComInfor(id) {
      this.$router.push({ path: "/enterpriseDetails", query: { SysID: id } });
    },

    GetPageMore() {
      this.PageIndex = this.PageIndex + 1;
      this.GetNewsList();
    },

    // 获取新闻列表
    GetNewsList() {
      let that = this;
      this.$post("/api/ZMCase/List", {
        State: true,
        CState:true,
        CompanyTypeSysID: this.CompanyTypeSysID,
        SaleTypeSysID: this.SaleTypeSysID,
        Sort: "CreateOn desc",
        PageSize: this.PageSize,
        PageIndex: this.PageIndex,
      })
        .then(function (data) {
          console.log(data);
          data.Obj.forEach((v) => {
            if (v.FirstImg) {
               v.FirstImg = that.urlOA + v.FirstImg.substr(1);
            }
           
            v.CreateOn = timeFormat.timeFormat(v.CreateOn, 1);
            that.NewsList.push(v);
            console.log(that.NewsList);
          });
          if (data.Obj.length == that.PageSize) {
            that.IsShowMore = 1;
          } else {
            that.IsShowMore = 0;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    handleSelect(e) {
      let that = this;
      console.log(e);
      that.$router.push(e);
      setTimeout(() => {
        that.drawer = false;
      }, 500);
    },
    getlogin() {
      this.$router.push("/login");
    },
    register() {
      this.$router.push("/register");
    },
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
    InformationDetails(SysID) {
      console.log(SysID);
      this.$router.push({
        path: "/ZMBcaseDetail",
        query: { SysID: SysID },
      });
    },

    GetNewCompanyList() {
      let that = this;
      that
        .$post("/api/UserInfo/List", {
          State: true,
          CState:1,
          Sort: "CreateOn desc",
          PageSize: 8,
          Type: 1,
          PageIndex: 1,
        })
        .then(function (data) {
          console.log(data);
          that.NewCompanyList = data.Obj;
          that.NewCompanyList.forEach((v) => {
            v.CompanyLogo = that.urlOA + v.CompanyLogo.substr(1);
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    var that = this;
 that.getLBsmail()
    that.GetNewsList();

    window.onresize = () => {
      return (() => {
        window.fullHeight = document.documentElement.clientHeight;
        window.fullWidth = document.documentElement.clientWidth;
        that.windowHeight = window.fullHeight; // 高
        that.windowWidth = window.fullWidth; // 宽
      })();
    };
  },
};
</script>


<style scoped  lang="scss">

.title {
  width: 100%;
  height: 80px;
  background-color: #eee;
min-width: 1200px;
  display: flex;
  justify-content: center;
  .titleBox {
    margin-top: 20px;
    height: 40px;
    margin-left: 40px;
    margin-right: 20px;
    display: flex;
    align-items: center;
    cursor: pointer;
    padding-right: 10px;
    img {
      height: 40px;
    }
    span {
      margin-left: 10px;
      font-weight: 550;
    }
  }
}
.textbox {
  width: 1200px;
  margin-left: 50%;
  transform: translateX(-50%);
  padding-top: 30px;
  overflow: hidden;
}
.listbox {
  float: left;
  width: 380px;
  margin-bottom: 20px;
  margin-right: 20px;
  // height: 320px;
  box-shadow: 0 2px 3px #ddd;
  border: 1px solid #eee;
  cursor: pointer;
  box-sizing: border-box;
  border-radius: 2px;
  .listbox:hover {
   box-shadow: 0 5px 3px #ddd;
  }
}
.imgbox {
  width: 380px;
  height: 200px;
}
.listbox:hover .imgbox::v-deep .el-image__inner{
height: 112% !important;
width: 112% !important;
margin-left: -6%;
margin-top: -6%;

}
.midbox {
  height: 130px;
  background-color: #fcfcfc;
  .title {
    padding: 0 10px;
    height: 50px;
    line-height: 50px;
    font-size: 17px;
    font-weight: 600;
    box-sizing: border-box;
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    min-width: 100px;
    line-clamp: 1;
    -webkit-box-orient: vertical;
  }
  .Intro {
    padding:10px;
    height: 80px;
    line-height: 32px;
    font-size: 14px;
    color: #888;
    box-sizing: border-box;
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}
.bottombox {
  margin-top: 1px;
  height: 50px;
  background-color: #f3f7fb;
  font-size: 14px;
  color: #666;
  .adress {
    float: left;
    padding: 0 10px;
    box-sizing: border-box;
    width: 280px;
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
    height: 50px;
    line-height: 50px;
  }
  .time {
    float: left;
    height: 50px;
    line-height: 50px;
    font-size: 13px;
    width: 80px;
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
  }
}
@media (max-width: 500px) {

  .textbox {
  width: 96%;
  margin-left: 50%;
  transform: translateX(-50%);
  padding-top: 30px;
  overflow: hidden;
    .el-breadcrumb{
    padding-left: 5px;
    box-sizing: border-box;
  }
}
.listbox {
  float: left;
  width: 100%;
  margin-bottom: 20px;
  margin-right: 0;
  // height: 320px;
  box-shadow: 0 2px 3px #ddd;
  border: 1px solid #eee;
  cursor: pointer;
  box-sizing: border-box;
  border-radius: 2px;
  .listbox:hover {
   box-shadow: 0 5px 3px #ddd;
  }
}
.imgbox {
  width: 100%;
  height: 200px;
}
.midbox {
  height: 130px;
  background-color: #fcfcfc;
  .title {
    padding: 0 10px;
    height: 50px;
    line-height: 50px;
    font-size: 17px;
    font-weight: 600;
    box-sizing: border-box;
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
  }
  .Intro {
    padding:10px;
    height: 80px;
    line-height: 32px;
    font-size: 14px;
    color: #888;
    box-sizing: border-box;
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}
.bottombox {
  margin-top: 20px;
  height: 50px;
  background-color: #f3f7fb;
  font-size: 14px;
  color: #666;
  .adress {
    float: left;
    padding: 0 10px;
    box-sizing: border-box;
    width: 200px;
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
    height: 50px;
    line-height: 50px;
  }
  .time {
    float: right;
    margin-left: 10px;
    height: 50px;
    line-height: 50px;
    font-size: 13px;
    width: 80px;
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
  }
}

 .title {
    width: 100%;
    height: 60px;
    background-color: #eee;
    box-sizing: border-box;
    min-width: 300px;
    display: flex;
    justify-content: center;
    .titleBox {
      margin-top: 20px;
      height: 20px;
       margin-left: 10px;
    margin-right: 0;
      display: flex;
      align-items: center;
      cursor: pointer;
      padding-right: 10px;
      img {
        display: none;
        height: 20px;
        width: 25px;
      }
      span {
        font-size: 12px;
        margin-left: 10px;
        font-weight: 550;
      }
    }
  }
}
</style>